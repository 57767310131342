import {
  dialogBackgroundColor,
  borderRadiusPopup,
  fontColorSecondary,
  tertiaryColor,
  iconBasicColor,
  theme,
  primaryColor,
  fontColorBody,
  fontFamilyName,
  secondaryColor,
  elementHover,
} from "../../../styles/mui/theme";

export const muiStylesPopup = {
  backgroundDialog: {
    fontFamily: fontFamilyName,
    backgroundColor: dialogBackgroundColor,
  },
  dialogPaper: {
    overflowY: "none",
    fontFamily: fontFamilyName,
    borderRadius: borderRadiusPopup,
    maxWidth: "438px",
    minHeight: "210px",
    maxhHeight: "570px",
    backdrop: {
      backgroundColor: dialogBackgroundColor,
      backdropFilter: "blur(10px)",
    },
  },
  dialogContent: {
    fontFamily: fontFamilyName,
    display: "flex",
    flexDirection: "column",
    paddingX: "39px",
  },
  dialogHeader: {
    fontFamily: fontFamilyName,
    textAlign: "center",
    fontWeight: 700,
    padding: "10px",
    color: fontColorSecondary,
    marginTop: "12px",
    marginBottom: "4px",
  },
  dialogFormContent: {
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "fontFamily": fontFamilyName,
    "display": "flex",
    "flexDirection": "row",
    "justifyContent": "center",
    "itemAlign": "center",
    "paddingTop": "3px",
    "paddingBottom": "6px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "15px",
    },
  },
  checkbox: {
    "& .MuiTypography-root": {
      fontFamily: fontFamilyName,
    },
    "fontFamily": fontFamilyName,
    "justifyContent": "center",
    "color": tertiaryColor,
    "&.Mui-checked, &.MuiCheckbox-indeterminate": {
      color: primaryColor,
    },
    "&.MuiFormControlLabel-root, &.MuiFormControlLabel-label": {
      color: fontColorBody,
    },
  },
} as const;

export const muiStylesMoveExamineePopup = {
  dialogPaper: {
    fontFamily: fontFamilyName,
    borderRadius: borderRadiusPopup,
    width: "520px",
    backdrop: {
      backgroundColor: dialogBackgroundColor,
      backdropFilter: "blur(10px)",
    },
  },
  dialogHeader: {
    fontFamily: fontFamilyName,
    display: "flex",
    justifyContent: "space-between",
  },
  icon: iconBasicColor,
  buttonContainer: {
    fontFamily: fontFamilyName,
  },
  // selectedDirectory: {
  //   fontFamily: fontFamilyName,
  //   backgroundColor: primaryColor,
  //   color: secondaryColor,
  //   fontWeight: "bold",
  // },
  list: {
    fontFamily: fontFamilyName,
    backgroundColor: secondaryColor,
    color: fontColorBody,
    border: "1px solid",
    borderColor: primaryColor,
    display: "flex",
    flexDirection: "column",
    maxHeight: "200px",
    overflowY: "scroll",
  },
  listItem: {
    "&&.Mui-selected": {
      fontFamily: fontFamilyName,
      backgroundColor: primaryColor,
      color: secondaryColor,
      fontWeight: "bold",
    },
    ":hover": {
      fontFamily: fontFamilyName,
      backgroundColor: elementHover,
    },
  }
} as const;

export const muiStylesDirectoryPopup = {
  dialogPaper: {
    fontFamily: fontFamilyName,
    width: "100%",
    maxWidth: "520px",
    borderRadius: borderRadiusPopup,
    backdrop: {
      backgroundColor: dialogBackgroundColor,
      backdropFilter: "blur(10px)",
    },
  },
  icon: iconBasicColor,
  dialogHeader: {
    fontFamily: fontFamilyName,
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "0",
    color: fontColorSecondary,
  },
  buttonContainer: {
    fontFamily: fontFamilyName,
    marginRight: "17px",
    gap: "15px",
  },
};

export const muiStylesSuggestedPsychologicalTestsPopup = {
  dialogPaper: {
    ...muiStylesPopup.dialogPaper,
    maxWidth: "500px",
    height: "auto",
  },
  dialogHeader: {
    ...muiStylesPopup.dialogHeader,
    "&.MuiTypography-root": { "& div": { lineHeight: "32px" }, "fontFamily": fontFamilyName },
  },
};
