import {Theme} from "@mui/material";
import {muiStylesPopup} from "../muistylesPopup";

export const muiStylesCookiesPopup = (theme: Theme) => ({
  backgroundDialog: {
    ...muiStylesPopup.backgroundDialog,
  },
  dialogPaper: {
    ...muiStylesPopup.dialogPaper,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    margin: 0,
    alignSelf: "end",
    width: "100%",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "18px",
    }
  },
  dialogActions: {
    justifyContent: "center",
    paddingBottom: "9px",
    paddingTop: "33px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "end",
      padding: "21px 0 9px",
    }
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: "0 43px",
    [theme.breakpoints.up("md")]: {
      padding: "0",
    }
  },
  dialogHeader: {
    ...muiStylesPopup.dialogHeader,
    marginTop: "18.6px",
    marginBottom: "2.6px",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    }
  },
} as const);
