import React, {FC, useState} from "react";
import * as styles from "./cookiesPopup.module.scss";
import { muiStylesCookiesPopup } from "./muistylesCookiesPopup";
import { I18n } from "react-redux-i18n";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { useCookies } from 'react-cookie';

const CookieValidYears = 3
const CookieName = 'cookiesConsent'
const CookiesAcceptedValue = 'ConsentGiven'

export const CookiesPopup: FC = () => {
  const [cookies, setCookies] = useCookies([CookieName])
  const [isOpen, setIsOpen] = useState(!(cookies && Object.hasOwnProperty.call(cookies, CookieName) && cookies[CookieName] === CookiesAcceptedValue));

  const daysToExpireCookie = new Date(new Date().setFullYear(new Date().getFullYear() + CookieValidYears))

  const onAcceptButton = () => {
    setCookies('cookiesConsent', CookiesAcceptedValue, {path: "/", expires: daysToExpireCookie})
    setIsOpen(false);
  }

  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      PaperProps={{ sx: (theme) => muiStylesCookiesPopup(theme).dialogPaper }}
    >
      <DialogTitle
        // use undocumented `component` prop to render DialogTitle as div instead of <h2> tag;
        // Object destructuring used as a typescript workaround (not to raise an error)
        {...{ component: 'div' } as any}
        sx={(theme) => muiStylesCookiesPopup(theme).dialogHeader}
      >
        <h1>{I18n.t("Cookies.header")}</h1>
      </DialogTitle>
      <DialogContent sx={(theme) => muiStylesCookiesPopup(theme).dialogContent}>
        <div className={styles.cookiesContentContainer}>{I18n.t("Cookies.consent")}</div>
      </DialogContent>
      <DialogActions sx={(theme) => muiStylesCookiesPopup(theme).dialogActions}>
        <TertiaryButton text={I18n.t("Buttons.accept")} event={onAcceptButton} />
      </DialogActions>
    </Dialog>
  );
};