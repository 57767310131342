import * as styles from "../authentication.module.scss";
import React, { FC, useEffect, useRef, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Link } from "gatsby";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { I18n } from "react-redux-i18n";
import { loginSchema } from "../schema";
import { login, loginAdmin } from "../../../redux/actions/authActions";
import {
  commonFormBase,
  muiStylesAuthentication,
} from "../muiStylesAuthentication";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { selectAuthUserError } from "../../../redux/selectors/authSelectors";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  handleNavigateExpiredPasswordPage,
  resetPasswordGetEmailPath,
} from "../../../utils/paths";
import PasswordExpiredPopup from "../../Common/Popup/PasswordExpiredPopup";
import ReCAPTCHA from "react-google-recaptcha";
import { reformatMessageToKey } from "../../../utils/redux";
import { useIsCapsLockOn } from "../../../utils/hooks";
export type LoginData = {
  email: string;
  password: string;
};

interface Props {
  adminLogin?: boolean | undefined;
}

const Login: FC<Props> = ({ adminLogin }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const capsLock = useIsCapsLockOn();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const loginError = useSelector(selectAuthUserError);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const expiredUserId = useRef<string | undefined>(undefined);

  const isPasswordExpired = () => {
    const { details } = loginError;

    expiredUserId.current = loginError.userId;

    // check if errorDetail is a non-null object
    if (
      typeof details === "object" &&
      !Array.isArray(details) &&
      details !== null
    ) {
      // check if errorDetail has `message` key
      if (Object.prototype.hasOwnProperty.call(details, "message")) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const msg: string[] = details.message;

        // check if errorDetail.message is an array
        if (Array.isArray(msg)) {
          // check if errorDetail.message has `Password expired` error as the first item
          return msg[0].includes("Password expired");
        }
      }
    }

    return false;
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onTouched",
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = handleSubmit(async (data: LoginData) => {
    const captchaToken = process.env.PUBLIC_RECAPTCHA_SITE_KEY
      ? await recaptchaRef.current?.executeAsync()
      : null;

    dispatch(
      adminLogin ?
        loginAdmin({
          email: data.email.toLowerCase(),
          captchaToken,
          password: data.password,
        })
        :
        login({
          email: data.email.toLowerCase(),
          captchaToken,
          password: data.password,
        })
    );

    recaptchaRef.current?.reset();
  });

  useEffect(() => {
    isPasswordExpired() && setOpenPopup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError.details]);

  return (
    <>
      <div>
        <form onSubmit={onSubmit} className={styles.loginForm}>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={
              (process.env.PUBLIC_RECAPTCHA_SITE_KEY as string) || "invalid key"
            }
          />
          {adminLogin && <h1>{I18n.t("Login.adminHeader")}</h1>}
          {loginError.error && !isPasswordExpired() && (
            <p className={styles.loginError}>
              {loginError.message !== "Validation failed"
                ? I18n.t(
                  `Login.${reformatMessageToKey(
                    loginError?.message as string
                  )}`
                )
                : I18n.t("Login.error")}
            </p>
          )}
          <Controller
            name="email"
            render={({ field }) => (
              <TextField
                autoComplete="off"
                variant="outlined"
                error={!!errors.email}
                label={I18n.t("FormLabels.email")}
                type="text"
                sx={muiStylesAuthentication.inputEmail}
                inputProps={{
                  style: muiStylesAuthentication.inputEmail
                    .lowercase as React.CSSProperties,
                }}
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            control={control}
          />
          <Controller
            name="password"
            render={({ field }) => (
              <TextField
                label={I18n.t("FormLabels.password")}
                sx={muiStylesAuthentication.inputPassword}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                error={!!errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        sx={commonFormBase.passwordVisibility}
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...field}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
            control={control}
          />
          <div className={styles.loginMessagesContainer}>
            {capsLock && (
              <div className={styles.capslockContainer}>
                <ErrorOutlineOutlinedIcon fontSize="small" />
                <h3 className={styles.h3TypographyError}>{I18n.t("Login.warning")}</h3>
              </div>
            )}

            <Link
              className={styles.loginNavigateLink}
              to={resetPasswordGetEmailPath}
            >
              {I18n.t("Login.reset")}
            </Link>
          </div>

          <PrimaryButton text={I18n.t("Buttons.login")} loginBtn isSubmit />
        </form>
      </div>
      <PasswordExpiredPopup
        isOpen={openPopup}
        onAcceptButton={() => handleNavigateExpiredPasswordPage(expiredUserId.current ? expiredUserId.current : "")}
      />
    </>
  );
};

export default Login;
