import * as styles from "../popup.module.scss";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import React, { FC } from "react";
import { I18n } from "react-redux-i18n";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { muiStylesPopup } from "../muistylesPopup";
import { PasswordExpiredPopupProps } from "../types";


const PasswordExpiredPopup: FC<PasswordExpiredPopupProps> = ({
  isOpen,
  onAcceptButton,
}) => {
  return (
    <Dialog
      maxWidth="md"
      open={isOpen}
      PaperProps={{ sx: muiStylesPopup.dialogPaper }}
    >
      <DialogTitle sx={muiStylesPopup.dialogHeader}>
        <>
          <h1>{I18n.t("PasswordExpired.popup.header")}</h1>
        </>
      </DialogTitle>
      <DialogContent sx={muiStylesPopup.dialogContent}>
        <p className={styles.contentContainer}>
          {I18n.t("PasswordExpired.popup.text")}
        </p>
        <div className={styles.buttonsContainer}>
          <TertiaryButton
            text={I18n.t("Buttons.setPassword")}
            event={onAcceptButton}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PasswordExpiredPopup;
